/* You can add global styles to this file, and also import other style files */
@import '~handsontable/dist/handsontable.full.css';
@import '~c3/c3.min.css';

html, body { 
    /* height: 100%;  */
    min-height: 100%;
    height: auto;
    margin: 0;
    /* overflow: hidden; */
}

body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow-x: hidden;
}

.header {
    height: 7vh;
    width: 100vw;
    border-bottom: 1px solid grey;
    box-shadow: 0px 1px 1px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    overflow-x: hidden;
}

.tab-container {
    float: left;
}

.user-controls-container {
    float: right;
}

.header > button {
    margin: 3px;
}

.grid {
    display: grid;
    grid-template-columns: repeat( 9, 1fr );
    grid-template-rows: repeat( 9, 1fr );
    width: 99vw;
    height: 91vh;
}

.grid-center-1x1 {
    grid-column: 5 / 5;
    grid-row: 5 / 5;
}

.grid-main-view {
    grid-column: 2 / 10;
    grid-row: 1 / 10;
    padding: 5px;
    overflow: auto;
}

.grid-main-view-full {
    grid-column: 1 / 10;
    grid-row: 1 / 10;
    padding: 5px;
}

.grid-side-bar {
    grid-column: 1 / 2;
    grid-row: 1 / 10;
    padding: 5px 5px 0px 5px;

    border-right: 1px solid grey;
    box-shadow: 0px 1px 1px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid; 
    place-items: center;
    background-color: gray;
    opacity: .5;
}
